export default class TeensEconomicNewsModel {
  constructor() {
    this.objRaw = {};
    this.id = '';
    this.status = 'HIDDEN';
    this.use_comments = false;
    this.thumbnail = [];
    this.share_thumbnail = [];
    this.video_thumbnail = [];
    this.video_url = [];
    this.title = '';
    this.description = '';
    this.link = '';
    this.post_type = 'NORMAL';
    this.contents = '';
    this.view_count = null;
    this.created_at = '';
    this.updated_at = '';
  }
  setData(obj) {
    if (obj) this.objRaw = obj;
    const {
      id,
      status,
      use_comments,
      thumbnail,
      share_thumbnail,
      video_thumbnail,
      video_url,
      title,
      description,
      link,
      post_type,
      contents,
      view_count,
      created_at,
      updated_at,
    } = obj;
    this.id = id;
    this.status = status;
    this.use_comments = use_comments;
    if (thumbnail) {
      this.thumbnail = [
        {
          src: thumbnail,
        },
      ];
    }
    if (share_thumbnail) {
      this.share_thumbnail = [
        {
          src: share_thumbnail,
        },
      ];
    }
    if (video_thumbnail) {
      this.video_thumbnail = [
        {
          src: video_thumbnail,
        },
      ];
    }
    if (video_url) {
      this.video_url = [
        {
          src: video_url,
        },
      ];
    }
    this.title = title;
    this.description = description;
    this.post_type = post_type;
    this.link = link;
    this.contents = contents;
    this.view_count = view_count;
    this.created_at = created_at;
    this.updated_at = updated_at;
  }
  setStatusHidden() {
    this.status = 'HIDDEN';
  }
  // setPostTypeModifyData(obj, status) {
  //   // status : 'ADD','REMOVE'
  //   const { title, link, contents } = obj;
  //   let rObj = {
  //     title: title,
  //     post_type: '',
  //     link: link,
  //     contents: contents,
  //   };
  //   switch (status) {
  //     case 'ADD': {
  //       rObj.post_type = 'HOT';
  //       break;
  //     }
  //     case 'REMOVE': {
  //       rObj.post_type = 'NORMAL';
  //       break;
  //     }
  //   }
  //   return rObj;
  // }
  getRegisterData() {
    let obj = {
      status: this.status,
      use_comments: this.use_comments,
      thumbnail: this.thumbnail.length > 0 ? this.thumbnail[0].src : '',
      share_thumbnail:
        this.share_thumbnail.length > 0 ? this.share_thumbnail[0].src : '',
      video_thumbnail: this.video_thumbnail.length > 0 ? this.video_thumbnail[0].src : '',
      video_url: this.video_url.length > 0 ? this.video_url[0].src : '',
      title: this.title,
      description: this.description,
      post_type: this.post_type,
      link: this.link,
      contents: this.contents,
    };

    return obj;
  }
  getModifyData() {
    let obj = {
      status: this.status,
      use_comments: this.use_comments,
      thumbnail: this.thumbnail.length > 0 ? this.thumbnail[0].src : '',
      share_thumbnail:
        this.share_thumbnail.length > 0 ? this.share_thumbnail[0].src : '',
      video_thumbnail: this.video_thumbnail.length > 0 ? this.video_thumbnail[0].src : '',
      video_url: this.video_url.length > 0 ? this.video_url[0].src : '',
      title: this.title,
      description: this.description,
      post_type: this.post_type,
      link: this.link,
      contents: this.contents,
    };
    return obj;
  }
}
